import { AppBar, Hidden, Toolbar, Typography } from "@mui/material";
import {
  Input,
  Menu as MenuIcon,
  Settings as SettingsIcon
} from "@mui/icons-material";
import { makeStyles } from '@mui/styles';
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {useDispatch, useSelector} from "react-redux";
import { Link as RouterLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import {signOutRequest, logoutAsync} from "../../../features/authSlice";
import {
  getSessionCompanyName,
  getSessionRooms,
  setSessionCalendarSettings, setSessionCompanyIsRegistrable,
  setSessionCompanyName
} from "../../../helpers/sessionHelper";
import EnhancedIconButton from "../../enhanced/EnhancedIconButton";
import Settings from "../../Settings";
import {fetchCompanyList} from "../../../features/listsSlice";

function Topbar(props) {
  const { onSidebarOpen } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const companyList = useSelector((state) => state.lists.companyList);
  const [headerLabel, setHeaderLabel] = useState(getSessionCompanyName());
  const [isShowSettings, setShowSettings] = useState(true);

  const onSignOut = () => {
    dispatch(signOutRequest(history));
    dispatch(logoutAsync());
  };

  const [openSettings, setOpenSettings] = useState(false);
  useEffect(() => {
    companyList.length === 0 && dispatch(fetchCompanyList());
    if (
      companyList.length === 1
      && companyList.at(0).roomList.length === 1
      && Object.keys(getSessionRooms()).length === 0
    ) {
      var roomId = companyList.at(0).roomList.at(0).id;
      var companyId = companyList.at(0).id;
      setShowSettings(false);
      setSessionCompanyName(companyList.at(0).name);
      setSessionCompanyIsRegistrable(companyList.at(0).isRegistrable);
      setSessionCalendarSettings(JSON.parse('{"companyId": ' + companyId + ', "' + roomId + '": true}'));
      setHeaderLabel(getSessionCompanyName());
    } else if (
      (
        companyList.length > 1
        && Object.keys(getSessionRooms()).length === 0
      ) ||
      (
        companyList.length == 1
        && Object.keys(getSessionRooms()).length === 0
        && companyList.at(0).roomList.length !== 1
      )
    ) {
      setOpenSettings(true);
    }
  }, [companyList, headerLabel]);

  return (
    <AppBar className={classes.root}>
      <Toolbar>
        <RouterLink to="/" className={classes.title}>
          <Typography variant="h3" component="h1" className={classes.title} sx={titleStyle.title}>
            {headerLabel}
          </Typography>
        </RouterLink>
        <div className={classes.flexGrow} />
        <Hidden lgUp>
          <EnhancedIconButton ariaLabel="menu" tooltip="Menu" color="inherit" Icon={MenuIcon} onClick={onSidebarOpen} />
        </Hidden>
        {
          isShowSettings ? (
            <EnhancedIconButton
              ariaLabel="settings"
              tooltip="Nastavení"
              color="inherit"
              Icon={SettingsIcon}
              onClick={() => setOpenSettings(true)}
            />
          ) : null
        }
        <EnhancedIconButton ariaLabel="logout" tooltip="Odhlásit" color="inherit" Icon={Input} onClick={onSignOut} />
      </Toolbar>
      <Settings
        openSettings={openSettings}
        setOpenSettings={setOpenSettings}
        companyList={companyList}
        setHeaderLabel={setHeaderLabel}
      />
    </AppBar>
  );
}

const titleStyle = {
  title: {
    color: "white",
    textDecoration: "none"
  }
}

Topbar.propTypes = {
  className: PropTypes.string,
  onSidebarOpen: PropTypes.func
};

const useStyles = makeStyles((theme) => ({
  root: {
    boxShadow: "none"
  },
  flexGrow: {
    flexGrow: 1
  },
  signOutButton: {
    marginLeft: theme.spacing(1)
  }
}));


export default Topbar;
