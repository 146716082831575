import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EnhancedTextField from "../../enhanced/EnhancedTextField";
import { createCustomer, fetchFilteredCustomers } from "../../../features/modalEditSlice";
import alertHelper from "../../../helpers/alertHelper";
import {getSessionCheckedCompany, getSessionCompanyIsRegistrable} from "../../../helpers/sessionHelper";
import EnhancedConfirmButton from "../../enhanced/EnhancedConfirmButton";
import EnhancedCloseButton from "../../enhanced/EnhancedCloseButton";

const filter = createFilterOptions();

export default function AutoCompInputCustomer(props) {
  const dispatch = useDispatch();
  const filteredCustomersList = useSelector((state) => state.modalEdit.filteredCustomersList);

  const { value, setFormState } = props;

  const [stateValue, setValue] = useState(value?.name || "");
  const [open, toggleOpen] = useState(false);
  const [dialogValue, setDialogValue] = useState({
    firstName: "",
    lastName: "",
    phone: ""
  });

  useEffect(() => {
    setValue(value?.name ?? `${value?.fullName ?? ""}${value?.phone ? ` ${value?.phone}` : ""}`);
  }, [value]);

  function handleClose() {
    setDialogValue({
      firstName: "",
      lastName: "",
      phone: ""
    });
    toggleOpen(false);
  }

  function handleSubmit(event) {
    event.preventDefault();

    // phone validation
    const re = /^[0-9\b]+$/;
    if (!re.test(dialogValue.phone)) {
      alertHelper.error({ message: "Telefoní číslo musí být číslo." });
      return false;
    }

    dispatch(
      createCustomer({
        firstName: dialogValue.firstName,
        lastName: dialogValue.lastName,
        phone: dialogValue.phone,
        companyId: getSessionCheckedCompany()
      })
    );
    handleClose();
  }

  function handleAutoCompChange(event, newValue) {
    if (getSessionCompanyIsRegistrable() === 'true') {
      return false;
    }

    if (typeof newValue === "string") {
      // timeout to avoid instant validation of the dialog's form.
      setTimeout(() => {
        toggleOpen(true);
        setDialogValue({
          firstName: "",
          lastName: newValue,
          phone: ""
        });
      });
    } else if (newValue && newValue.inputValue) {
      toggleOpen(true);
      setDialogValue({
        firstName: "",
        lastName: newValue.inputValue,
        phone: ""
      });
    } else {
      handleCustomerChange(newValue);
      setValue(newValue);
    }
  }

  function handleCustomerChange(customer) {
    setFormState((formState) => ({
      ...formState,
      customer: customer
    }));

    if (customer) {
      setFormState((formState) => ({
        ...formState,
        title: `${formState.title} ${customer?.fullName}`
      }));
    }
  }

  function getAutoCompFilterOpts(options, params) {
    const filtered = filter(options, params);
    if (params.inputValue !== "" && params.inputValue.length >= 3) {
      dialogValue.firstName !== params.inputValue &&
        setTimeout(() => {
          setDialogValue((prevState) => {
            dispatch(fetchFilteredCustomers(params.inputValue));
            return { ...dialogValue, firstName: params.inputValue };
          });
        });

      if (getSessionCompanyIsRegistrable() === 'false') {
        filtered.push({
          inputValue: params.inputValue
        });
      }
    }
    return filtered;
  }

  function getAutoCompOptLabel(option) {
    // e.g value selected with enter, right from the input
    if (typeof option === "string") {
      return option;
    }

    if (option.inputValue) {
      return "Přidat " + option.inputValue;
    }
    return `${option.fullName}${option.phone ? ` ${option.phone}` : ""}`;
  }

  const handleChange = (e) => {
    e.persist();
    setDialogValue((dialogValue) => ({
      ...dialogValue,
      [e.target.name]: e.target.value
    }));
  };

  return (
    <React.Fragment>
      <Autocomplete
        value={stateValue}
        onChange={(event, newValue) => handleAutoCompChange(event, newValue)}
        filterOptions={(options, params) => getAutoCompFilterOpts(options, params)}
        id="free-solo-dialog-demo"
        options={filteredCustomersList}
        getOptionLabel={(option) => getAutoCompOptLabel(option)}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        style={{ width: "100%" }}
        freeSolo
        renderInput={(params) => <TextField required {...params} label="Zákazník" variant="outlined" />}
      />
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <form>
          <DialogTitle id="form-dialog-title">Přidat nový</DialogTitle>
          <DialogContent>
            <DialogContentText>Přidat nového zákazníka</DialogContentText>
            <EnhancedTextField
              name="lastName"
              value={dialogValue.lastName}
              label="Příjmení"
              onChange={handleChange}
              margin="dense"
            />
            <EnhancedTextField
              name="firstName"
              value={dialogValue.firstName}
              label="Jméno"
              onChange={handleChange}
              margin="dense"
            />
            <EnhancedTextField
              name="phone"
              value={dialogValue.phone}
              label="Telefon"
              onChange={handleChange}
              margin="dense"
            />
          </DialogContent>
          <DialogActions>
            <EnhancedCloseButton handleClose={handleClose} />
            <EnhancedConfirmButton handleOk={handleSubmit} okLabel="Přidat" />
          </DialogActions>
        </form>
      </Dialog>
    </React.Fragment>
  );
}

AutoCompInputCustomer.propTypes = {
  filteredCustomersList: PropTypes.array
};

AutoCompInputCustomer.defaultProps = {
  filteredCustomersList: []
};
