import {
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent, FormControl,
  FormControlLabel,
  FormGroup,
  Grid, InputLabel, ListItemText, MenuItem, OutlinedInput, Select
} from "@mui/material";
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { editCompanyUser, fetchCompaniesByUser } from "../../features/usersSlice";
import EnhancedConfirmButton from "../enhanced/EnhancedConfirmButton";
import EnhancedDialogTitle from "../enhanced/EnhancedDialogTitle";
import { fetchCompanyList } from "../../features/listsSlice";
import EnhancedSelect from "../enhanced/EnhancedSelect";

function UserCompanyDialog(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const companiesUserList = useSelector((state) => state.users.companiesUserList);
  const companyList = useSelector((state) => state.lists.companyList);
  const aclRoleList = useSelector((state) => state.lists.aclRoleList);

  const { open, setOpen, user } = props;
  const [formState, setFormState] = useState({});

  useEffect(() => {
    if (open && user) {
      dispatch(fetchCompaniesByUser(user.id));
      dispatch(fetchCompanyList());
    }
  }, [open, user, dispatch]);

  useEffect(() => {
    if (open && user) {
      let tempFormState = {};
      companiesUserList.forEach((c) => {
        if (tempFormState[c.companyId]) {
          tempFormState[c.companyId].push(c.aclRole.id);
        } else {
          tempFormState[c.companyId] = [c.aclRole.id];
        }
      });

      setFormState(tempFormState);
    }
  }, [open, user, companiesUserList]);

  const [isByUserFetching, setIsByUserFetching] = useState(true);
  useEffect(() => {
    setIsByUserFetching(companyList.length === 0);
  }, [companiesUserList, companyList]);

  const handleChange = (event) => {
    const {
      target: { name, value },
    } = event;

    setFormState((formState) => ({
      ...formState,
      [name]: value
    }));
  };

  function handleClose() {
    setOpen(false);
    setFormState({});
    setIsByUserFetching(true);
  }

  function handleOk() {
    const apiData = {};
    apiData.userId = user.id;
    apiData.companyIdsWithAclRole = formState;

    dispatch(editCompanyUser(apiData));
    handleClose();
  }

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  return (
    <div className={classes.root}>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="md" aria-labelledby="form-dialog-title">
        <EnhancedDialogTitle handleClose={handleClose} nameLabel="Uživatel v organizacích" />
        <DialogContent>
          <Grid container spacing={3}>
            {isByUserFetching ? (
                <CircularProgress />
            ) : (
                companyList.map((r) => (
                    <Grid item xs={12}>
                      <div>
                        <FormControl sx={{marginTop: 1.5, width: '100%'}}>
                          <InputLabel>{r.name}</InputLabel>
                          <Select
                              multiple
                              name={String(r.id)}
                              value={formState[r.id] || []}
                              onChange={handleChange}
                              renderValue={(selected) =>
                                  selected
                                      .map((id) => aclRoleList.find((role) => role.id === id)?.name)
                                      .filter((name) => name)
                                      .join(', ')
                              }
                              MenuProps={MenuProps}
                              variant="outlined"
                          >
                            {aclRoleList.map((role) => (
                                <MenuItem value={role.id}>
                                  <Checkbox key={r.id+role.id} checked={formState[r.id]?.includes(role.id)} name={String(role.id)}/>
                                  <ListItemText primary={role.name}/>
                                </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                    </Grid>
                ))
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <EnhancedConfirmButton handleClose={handleClose} handleOk={handleOk} okLabel="Uložit"/>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1
  }
}));

export default UserCompanyDialog;
