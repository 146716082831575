import { Grid, Typography } from "@mui/material";
import { makeStyles } from '@mui/styles';
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import validate from "validate.js";
import {signInRequestAsync} from "../features/authSlice";
import EnhancedTextField from "./enhanced/EnhancedTextField";
import EnhancedButton from "./enhanced/EnhancedButton";

const SignIn = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const [formState, setFormState] = useState({
    isValid: false,
    values: {},
    touched: {},
    errors: {}
  });

  useEffect(() => {
    const errors = validate(formState.values, schema);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {}
    }));

    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        dispatch(signInRequestAsync({ values: formState.values, history }));
      }
    };

    document.addEventListener("keydown", listener);

    return () => {
      document.removeEventListener("keydown", listener);
    };

  }, [formState.values]);

  const handleChange = (event) => {
    event.persist();

    setFormState((formState) => ({
      ...formState,
      values: {
        ...formState.values,
        [event.target.name]: event.target.type === "checkbox" ? event.target.checked : event.target.value
      },
      touched: {
        ...formState.touched,
        [event.target.name]: true
      }
    }));
  };

  const handleSignIn = async (event) => {
    event.preventDefault();
    dispatch(signInRequestAsync({ values: formState.values, history }));
  };

  const hasError = (field) => (formState.touched[field] && formState.errors[field] ? true : false);

  return (
    <div className={classes.root}>
      <Grid className={classes.grid} container>
        <Grid className={classes.marginAutoContainer} item lg={8}>
          <div className={classes.content}>
            <div className={classes.contentBody}>
              <form className={classes.form}>
                <Typography className={classes.title} variant="h2">
                  Přihlášení
                </Typography>
                <Grid container spacing={2} sx={{marginTop: 0.5}}>
                  <Grid item xs={12}>
                    <EnhancedTextField
                      className={classes.textField}
                      error={hasError("email")}
                      fullWidth
                      helperText={hasError("email") ? formState.errors.email[0] : null}
                      label="Email"
                      name="email"
                      onChange={handleChange}
                      type="text"
                      value={formState.values.email || ""}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <EnhancedTextField
                      className={classes.textField}
                      error={hasError("password")}
                      fullWidth
                      helperText={hasError("password") ? formState.errors.password[0] : null}
                      label="Heslo"
                      name="password"
                      onChange={handleChange}
                      type="password"
                      value={formState.values.password || ""}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <EnhancedButton
                      onClick={handleSignIn}
                      label="Přihlásit se"
                      disabled={!formState.isValid}
                      color="primary"
                      sx={{float: "right"}}
                    />
                  </Grid>
                </Grid>
              </form>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

const schema = {
  email: {
    presence: { allowEmpty: false, message: "is required" },
    email: true,
    length: {
      maximum: 64
    }
  },
  password: {
    presence: { allowEmpty: false, message: "is required" },
    length: {
      maximum: 128
    }
  }
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    height: "100%"
  },
  name: {
    marginTop: theme.spacing(3),
    color: theme.palette.white
  },
  content: {
    height: "100%",
    display: "flex",
    flexDirection: "column"
  },
  contentBody: {
    marginTop: 50,
    flexGrow: 1,
    display: "flex",
    alignItems: "center",
    [theme.breakpoints.down("md")]: {
      justifyContent: "center"
    }
  },
  form: {
    padding: 100,
    flexBasis: 700,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  sugestion: {
    marginTop: theme.spacing(2)
  },
}));

export default SignIn;
